<template>
    <div class="main">
        <div class="top-img">
            <van-image class="top-img-bg" v-if="liveData.img" :src="liveData.img" fit="cover"></van-image>
            <div v-else class="top-img-bg"></div>
        </div>
        <div class="live-icon"> <span style="font-weight: bold">·</span> 直播</div>
        <div class="live-title">{{liveData.theme}}</div>
        <div class="live-time">{{liveData.timeDate}}</div>
        <div class="live-name">直播人<span style="font-weight: bold">·</span>{{liveData.userName}}</div>
        <div class="live-name">{{liveData.description}}</div>
        <div v-if="liveData.status === 1" class="bottom-button">
            <wx-open-launch-weapp
                id="launch-btn"
                username="gh_25e071b83ee0"
                :path="pathUrl">
                <script type="text/wxtag-template">
                    <style>
                        .enter-page-button{
                            color: #5DA3FA;
                            border: 1px solid #5DA3FA;
                            border-radius: 30px;
                            height: 40px;
                            line-height: 40px;
                            text-align: center;
                            width: 200px;
                            font-size: 18px;
                            z-index: 1003;
                        }
                    </style>
                    <div class="enter-page-button">进入直播间</div>
                </script>
            </wx-open-launch-weapp>
        </div>
        <div v-else-if="liveData.status === 0" class="bottom-button">
            <div>直播未开始</div>
        </div>
        <div v-else-if="liveData.status === 2" class="bottom-button">
            <div>直播已结束</div>
        </div>
        <div v-else-if="liveData.status === 3" class="bottom-button">
            <div>直播已过期</div>
        </div>
        <div v-else-if="liveData.status === 4" class="bottom-button">
            <div>直播已取消</div>
        </div>
        <!--进入直播间弹窗开始-->
        <!--<div class="enter-page-box">

        </div>-->
        <!--进入直播间弹窗结束-->
    </div>
</template>
<script src="https://res.wx.qq.com/open/js/jweixin-1.6.0.js"></script>
<script>
    import { getJsSdkConfig, getUserId, enterLive, outLive, liveAuth } from '@/api/live'
    import { Toast } from 'vant';
    // import wx from'weixin-js-sdk'
    import Cookies from "js-cookie";
    const wx = window.wx;
    export default {
        name: 'Share',
        components: {
        },
        data() {
            return {
                id: '',
                userid: '',
                liveId: '', // 直播id
                showEnter: true, // 是否展示进入直播间弹窗
                unionId : '',
                openId : '',
                pathUrl: '',
                liveData: '',
                jsSdkPath: 'https://res.wx.qq.com/open/js/jweixin-1.2.0.js'
            }},
        created() {
        },
        mounted:function(){
            // 每次进页面 刷新一次
            let isLoad=Cookies.get('isLoad')
            if (!isLoad) {
                Cookies.set('isLoad','1')
                window.location.reload();
            }
            let liveId = this.GetUrl('liveId')
            let id = this.GetUrl('id')
            // let query=this.$route.query;
            if(!liveId || !id){
                Toast('参数错误')
                return
            }
            this.liveId = liveId
            this.id = id
            // Cookies.set('liveUnionId', 'oPN705ylE3vbkfddo-EVdyarX4vY')
            // Cookies.set('liveOpenId', 'osuSl6F_f5Etf5ZtdcJ57YBFQ3J4')
            let userId=Cookies.get('liveUnionId')
            let openId=Cookies.get('liveOpenId')
            if(!userId || !openId){
                this.login()
            }else{
                this.unionId = userId
                this.openId = openId
                this.liveAuth()
                this.getConfig()
            }
            let _this = this
            document.addEventListener("visibilitychange", function(){
                if(document.hidden){
                    console.log(123)
                }else{
                    // 此处填写H5监听到关闭小程序的操作，比如wx.closeWindow();
                    _this.userOut()
                }
            })
        },
        methods: {
            // 动态加载js文件
            insertScriptTag () {
                const _this = this
                let playerScriptTag = document.getElementById('jsSdkScriptTag')
                // 如果这个tag不存在，则生成相关代码tag以加载代码
                if (playerScriptTag === null) {
                    playerScriptTag = document.createElement('script')
                    playerScriptTag.type = 'text/javascript'
                    playerScriptTag.src = this.jsSdkPath
                    playerScriptTag.id = 'jsSdkScriptTag'
                    let s = document.getElementsByTagName('head')[0]
                    s.appendChild(playerScriptTag)
                }
                if (playerScriptTag.loaded) {
                    _this.getConfig()
                } else {
                    playerScriptTag.addEventListener('load', () => {
                        playerScriptTag.loaded = true
                        _this.getConfig()
                    })
                }
            },
            // 判断用户是否可授权
            liveAuth(){
                let _this = this
                let postData={}
                postData.livingId=this.liveId
                postData.unionID=this.unionId
                postData.userOpenId=this.openId
                liveAuth(postData).then(response => {
                    if(response.code===200 || response.code===0){
                        _this.pathUrl= 'pages/watch/index?living_code=' + response.data.livingCode
                        this.liveData = response.data
                        response.data.reserveStart = response.data.reserveStart.replace(/-/g, '/')
                        response.data.week = this.getweekday(response.data.reserveStart)
                        const date = new Date(response.data.reserveStart);
                        response.data.month = date.getMonth() + 1
                        response.data.day = date.getDate()
                        response.data.beginHour = date.getHours().toString().padStart(2, "0")
                        response.data.beginMinutes = date.getMinutes().toString().padStart(2, "0")
                        // 获取结束时间
                        const endTime = new Date(response.data.reserveStart).getTime() + response.data.reserveLivingDuration * 1000
                        const endDate = new Date(endTime)
                        response.data.endHour = endDate.getHours().toString().padStart(2, "0")
                        response.data.endMinutes = endDate.getMinutes().toString().padStart(2, "0")
                        this.liveData.timeDate = response.data.month + '月' +response.data.day + '日' + ' ' + response.data.beginHour + ':' + response.data.beginMinutes + ' - ' + response.data.endHour + ':' + response.data.endMinutes
                    }else{
                        // Toast.fail('暂无权限');
                        return false
                    }
                })
            },
            // 进入直播间
            userEnter(){
                let postData={}
                postData.livingId=this.liveId
                postData.unionID=this.unionId
                postData.userOpenId=this.openId
                enterLive(postData).then(response => {
                    console.log(response)
                })
            },
            // 进入退出直播间
            userOut(){
                let userId=Cookies.get('liveUnionId')
                let openId=Cookies.get('liveOpenId')
                if(!userId || !openId){
                    Toast('登录异常')
                    return
                }
                // 判断直播是否开始
                if(this.liveData.status !== 1 && this.liveData.status !== 2){
                    return;
                }
                let postData={}
                postData.livingId=this.liveId
                postData.unionID=userId
                postData.userOpenId=openId
                outLive(postData).then(response => {
                    console.log(response)
                })
            },
            // 用户网页授权登录
            login(){
                let code= this.getCode("code");
                if(code){
                    let postData={}
                    postData.code=code
                    getUserId(postData).then(response => {
                        if(response.code===200 || response.code===0){
                            Cookies.set('liveUnionId', response.data.unionId)
                            Cookies.set('liveOpenId', response.data.openId)
                            this.unionId = response.data.unionId;
                            this.openId = response.data.openId;
                            this.liveAuth()
                            this.getConfig()
                        }else{
                            Toast.fail('获取userId失败');
                            return false
                        }
                    })
                }else{
                    let nowUrl = window.location.href;
                    let backurl = encodeURIComponent(nowUrl)
                    let url = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxea3d7a0f5abd4899&redirect_uri=' + backurl + '&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect'
                    window.location.href=url
                }
            },
            // 截取code
            getCode(parameName) {
                /// 获取地址栏指定参数的值
                // 获取url中跟在问号后面的部分
                var parames = window.location.search;
                console.log(parames);
                // 检测参数是否存在
                if (parames.indexOf(parameName) > -1) {
                    var parameValue = "";
                    parameValue = parames.substring(
                        parames.indexOf(parameName),
                        parames.length
                    );
                    // 检测后面是否还有参数
                    if (parameValue.indexOf("&") > -1) {
                        // 去除后面多余的参数, 得到最终 parameName=parameValue 形式的值
                        parameValue = parameValue.substring(0, parameValue.indexOf("&"));
                        // 去掉参数名, 得到最终纯值字符串
                        parameValue = parameValue.replace(parameName + "=", "");
                        return parameValue;
                    }
                    parameValue = parameValue.replace(parameName + "=", "");
                    return parameValue;
                }
            },
            // 获取参数
            GetUrl(parameName) {
                /// 获取地址栏指定参数的值
                // 获取url中跟在问号后面的部分
                var parames = window.location.hash;
                // 检测参数是否存在
                if (parames.indexOf(parameName) > -1) {
                    var parameValue = "";
                    parameValue = parames.substring(
                        parames.indexOf(parameName),
                        parames.length
                    );
                    // 检测后面是否还有参数
                    if (parameValue.indexOf("&") > -1) {
                        // 去除后面多余的参数, 得到最终 parameName=parameValue 形式的值
                        parameValue = parameValue.substring(0, parameValue.indexOf("&"));
                        // 去掉参数名, 得到最终纯值字符串
                        parameValue = parameValue.replace(parameName + "=", "");
                        return parameValue;
                    }
                    parameValue = parameValue.replace(parameName + "=", "");
                    return parameValue;
                }
            },
            // 获取jsSdk配置信息
            getConfig(){
                let postData={}
                postData.url=window.location.href.split('#')[0]
                getJsSdkConfig(postData).then(response => {
                    this.initJsSdk(response.data)
                })
            },
            // 计算指定时间是星期几
            getweekday(date){
                // date例如:'2022-03-05'
                var weekArray = ["周日","周一", "周二", "周三", "周四", "周五", "周六"]
                var week  = weekArray[new Date(date).getDay()]
                return week
            },
            // 监听点击事件
            initClick(){
                let _this = this
                let btn = document.getElementById('launch-btn');
                btn.addEventListener('launch', function (e) {
                    console.log(e);
                    _this.userEnter()
                });
                btn.addEventListener('error', function (e) {
                    console.log('fail', e.detail);
                });
            },
            // 初始化jsSdk
            initJsSdk(result){
                let _this = this
                wx.config({
                    appId: 'wx882ef50fd0e0cbc3',
                    nonceStr: result.noncestr,
                    signature: result.sign,
                    timestamp: result.timestamp,
                    debug:false,// 开启调试模式
                    jsApiList: ["updateAppMessageShareData","updateTimelineShareData","chooseImage", "previewImage"],
                    openTagList: ["wx-open-launch-weapp"]
                });
                wx.ready(function () {
                    let domain = window.location.host
                    // 自定义分享给朋友
                    wx.updateAppMessageShareData({
                        title: _this.liveData.theme, // 分享标题
                        desc: _this.liveData.description? _this.liveData.description: '直播人：' + _this.liveData.userName, // 分享描述
                        link: 'https://' + domain + '/living/#/share?liveId=' +_this.liveId+ '&id=' + _this.id, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
                        imgUrl: _this.liveData.img?_this.liveData.img:'https://tousubiaoyang-1301970825.file.myqcloud.com/chartVideo/16546807931942.png', // 分享图标
                        success: function () {
                            // 设置成功
                        }
                    })
                    // 自定义分享给朋友
                    wx.updateTimelineShareData({
                        title: _this.liveData.theme, // 分享标题
                        link: 'https://' + domain + '/living/#/share?liveId=' +_this.liveId+ '&id=' + _this.id, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
                        imgUrl: _this.liveData.img?_this.liveData.img:'https://tousubiaoyang-1301970825.file.myqcloud.com/chartVideo/16546807931942.png', // 分享图标
                        success: function () {
                            // 设置成功
                        }
                    })
                    _this.initClick()
                });
                wx.error(function (res) {
                    console.log(res)
                })

            },
        }
    }
</script>

<style scoped>
    .main{
        width: 100%;
        min-height: 100vh;
        position: relative;
    }
    .top-img-bg{
        width: 100vw;
        height: 200px;
        background-color: #9bc3f5;
    }
    .live-icon{
        width: 50px;
        text-align: center;
        background-color: #5DA3FA;
        padding: 5px 5px;
        color: #ffffff;
        border-radius: 5px;
        font-size: 12px;
        margin: 20px 0 0 20px;
    }
    .live-title{
        font-size: 28px;
        font-weight: bold;
        margin: 20px 0 0 20px;
    }
    .live-time{
        margin: 20px 0 0 20px;
    }
    .live-name{
        color: #898787;
        margin: 20px 0 0 20px;
        font-size: 13px;
    }
    .bottom-button{
        position: fixed;
        bottom: 50px;
        left: 0;
        width: 100vw;
        display: flex;
        justify-content: center;
    }
    .enter-page-box{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 1002;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba(7, 7, 7, 0.9);
    }
    .enter-page-button{
        color: #5DA3FA;
        border: 1px solid #5DA3FA;
        border-radius: 30px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        width: 200px;
        font-size: 18px;
        z-index: 1003;
    }
</style>
